import React, { useRef, useState } from "react";
import Home from "./pages/home/Home";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./App.css";

// import Swiper core and required modules
import { Mousewheel, Pagination } from "swiper/modules";

const pages = [<Home/>, <Home/>];

function App() {
  
  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        mousewheel={true}
        modules={[Mousewheel, Pagination]}
        className="swiper-container"
      >
        {pages.map((page) => (
          <SwiperSlide className="swiper-wrapper">
            {page}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default App;
