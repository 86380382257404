/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { InView } from "react-intersection-observer";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const menuOptions = [
  {
    iconName: "home icon",
    text: "Home",
    link: "https://www.google.com", 
  },
  {
    iconName: "paint brush icon",
    text: "Painting",
    link: "https://www.youtube.com", 
  },
  {
    iconName: "clone icon",
    text: "Drywall",
    link: "https://www.youtube.com", 
  },
  {
    iconName: "building icon",
    text: "Siding",
    link: "https://www.youtube.com", 
  },
  {
    iconName: "industry icon",
    text: "Roofing",
    link: "https://www.youtube.com", 
  },
  {
    iconName: "table icon",
    text: "Flooring",
    link: "https://www.youtube.com", 
  },
  {
    iconName: "tree icon",
    text: "Landscape",
    link: "https://www.youtube.com", 
  }
];

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as="h1"
      content="Trevys Zooming Fast Remodeling"
      inverted
      style={{
        fontSize: mobile ? "2em" : "4em",
        fontWeight: "normal",
        marginTop: mobile ? ".5em" : "3em",
        marginBottom: mobile ? "1em" : "3em",
      }}
    />
    <Header
      as="h2"
      content="Painting • Drywall • Siding • Roofing • Flooring • Landscaping"
      inverted
      style={{
        fontSize: mobile ? "1em" : "1.3em",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1.5em",
      }}
    />
    <Button
      primary
      size="huge"
      style={{
        fontSize: mobile ? "1.5em" : "1.7em",
        fontWeight: "normal",
        marginTop: mobile ? "1em" : "1.5em",
      }}
    >
      Get Quote
      <Icon name="right arrow" />
    </Button>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Media greaterThan="mobile">
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 700, padding: "1em 0em" }}
            vertical
          >
            <Menu
              fixed={fixed ? "top" : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item as="a" active>
                  Home
                </Menu.Item>
                {/* <Menu.Item as="a">Work</Menu.Item>
                <Menu.Item as="a">Company</Menu.Item>
                <Menu.Item as="a">Careers</Menu.Item>
                <Menu.Item position="right">
                  <Button as="a" inverted={!fixed}>
                    Log in
                  </Button>
                  <Button
                    as="a"
                    inverted={!fixed}
                    primary={fixed}
                    style={{ marginLeft: "0.5em" }}
                  >
                    Sign Up
                  </Button>
                </Menu.Item> */}
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </InView>

        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
            width="ui thin sidebar"
          > 
          {menuOptions.map((option) => (
            <Menu.Item as="a" href={option.link}>
          <Grid stackable padded verticalAlign="center">
          <Header stackable style={{ textAlign: "center", fontSize: "1.8em", color:"white"  }}>
          <Icon name={option.iconName} size="big" style={{marginRight: "0"}}/>
          
          {option.text}
        </Header>
          </Grid>
          </Menu.Item>
          
        ))}
            {/* <Menu.Item as="a">Work</Menu.Item>
            <Menu.Item as="a">Company</Menu.Item>
            <Menu.Item as="a">Careers</Menu.Item>
            <Menu.Item as="a">Log in</Menu.Item>
            <Menu.Item as="a">Sign Up</Menu.Item> */}
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign="center"
              style={{ minHeight: 350, padding: "1em 0em" }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size="large">
                  <Menu.Item position="left">
                  <Icon name="universal access loading icon" size="big"/>
                  </Menu.Item>
                  <Menu.Item position="right" onClick={this.handleToggle}>
                    <Icon name="sidebar" size="big"/>
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const Home = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: "3em 0em" }} vertical>
      <Grid container stackable verticalAlign="center">
        <Grid.Row>
          <Header as="h3" style={{ fontSize: "2em" }}>
            We offer a wide variety of services!
          </Header>
          <p style={{ fontSize: "1.33em", color: "black" }}>
            Praesent nec est eu nibh sodales malesuada id quis ante. Mauris nec
            efficitur leo, eu dapibus tellus. Proin mollis tellus ex, sagittis
            egestas orci bibendum eu.
          </p>
        </Grid.Row>
        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
          <a href="#">Swipe down to see more</a>
          <div style={{paddingTop: "1.5em", color: "#4183c4"}}>
          <Icon name="arrow down" />
          </div>
        </Divider>
      </Grid>
    </Segment>
  </ResponsiveContainer>
);

export default Home;
